/* Custom Fonts */
@font-face {
  font-family: Montserrat;
  src: url("./assets/font/Montserrat-Italic-VariableFont_wght.ttf");
}
@font-face {
  font-family: Montserrat;
  src: url("./assets/font/Montserrat-VariableFont_wght.ttf");
}
@font-face {
  font-family: PoiretOne;
  src: url("./assets/font/PoiretOne-Regular.ttf");
  font-weight: 400;
}

/* Default CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  /* background color */
  background-color: #276174 !important;
  /* font color */
  color: #fffef8 !important;
  font-family: "Montserrat" !important;
}

h1,
h2 {
  margin: 0;
  padding: 0;
  /* line height */
  line-height: normal;
  font-family: PoiretOne;
  color: #f6f1d1 !important;
}

h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  /* line height */
  line-height: normal;
}

.App {
  text-align: start;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fffef8;
}

.App-link {
  color: #a6c9c5;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/************ Navbar CSS ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  overflow: hidden;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #276174;
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link,
nav a {
  font-weight: 400;
  color: #fffef8 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
  text-decoration: none;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.logoimg {
  padding-top: 15px;
  padding-bottom: 20px;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: #40798c;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid #f6f1d1;
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #fffef8;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fffef8;
  border: 1px solid #fffef8;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fffef8;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #22404b;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fffef8;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fffef8;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fffef8;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fffef8;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fffef8;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner-body {
  background-color: #40798c;
}

.banner {
  margin-top: 0;
  padding: 180px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.center-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.banner .tagline {
  letter-spacing: 0.8px;
  padding: 8px 10px;
  border-radius: 15px;
  font-size: 30px;
  display: inline-block;
}
.banner h1 {
  font-size: 75px;
  font-weight: 600;
  letter-spacing: 4px;
  line-height: 1;
  display: block;
  text-align: start;
}
.banner p {
  color: #fffef8;
  font-size: 22px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  margin-top: 15px;
  text-align: start;
}
.banner h2 {
  color: #fffef8;
  font-family: "Montserrat" !important;
  font-weight: 300;
  font-size: 30px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

/* Pills css */
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-25px);
  }
  100% {
    transform: translatey(0px);
  }
}

.pill-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
  overflow: hidden;
  padding: 30px 0px;
}

.darkerbluebox {
  z-index: -1;
  margin-top: -100px;
  width: 100%;
  height: 90px;
  background-color: #276174;
}

.pill {
  height: 150px;
  width: 75px;
  border-radius: 50%/30%;
  flex-shrink: 0;
  transform: translatey(0px);
  margin: -5px;
  animation: float 10s ease-in-out infinite;
  min-width: 0;
}

.pill0 {
  z-index: 1;
  animation-delay: 0s;
  background-color: #70a9a1;
}

.pill1 {
  height: 125px;
  z-index: 0;
  animation-delay: 2s;
  background-color: #cfd7c7;
}

.pill2 {
  z-index: 1;
  height: 175px;
  border-radius: 50%/20%;
  animation-delay: 4s;
  background-color: #f6f1d1;
}

.pill3 {
  z-index: 2;
  animation-delay: 6s;
  background-color: #fffef3;
}

.pill4 {
  z-index: 0;
  height: 135px;
  animation-delay: 6s;
  background-color: #70a9a1;
}

.pill5 {
  z-index: 2;
  height: 120px;
  animation-delay: 4s;
  background-color: #f6f1d1;
}

.pill6 {
  z-index: 1;
  animation-delay: 2s;
  background-color: #cfd7c7;
}

.pill7 {
  z-index: 0;
  height: 175px;
  border-radius: 50%/20%;
  animation-delay: 0s;
  background-color: #fffef3;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
@media screen and (max-width: 990px) {
  .banner {
    margin-top: 0;
    padding: 150px 0 50px 0;
  }
  .banner .tagline {
    letter-spacing: 0.8px;
    padding: 8px 5px;
    font-size: 25px;
    display: inline-block;
    margin-bottom: 0;
  }
  .banner h1 {
    font-size: 40px;
    letter-spacing: 0.8px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  .banner h2 {
    font-size: 20px;
  }
  .banner p {
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 80%;
  }
  .banner img {
    width: 250px;
  }
  img.bubble-border {
    padding-bottom: 10px;
  }

  /* .pill-container {
    width: 200%;
  } */
}

/* tablet size */
@media screen and (max-width: 600px) {
  .banner {
    margin-top: 0;
    padding: 150px 0 50px 0;
  }
  .banner .tagline {
    letter-spacing: 0.8px;
    padding: 8px 5px;
    font-size: 30px;
    /* margin-bottom: 16px; */
    display: inline-block;
    margin-bottom: 0;
  }
  .banner h1 {
    font-size: 40px;
    letter-spacing: 0.8px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  .banner p {
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 80%;
  }
  .banner img {
    margin-top: 20px;
    width: 150px;
    border-radius: 2000px;
  }
  /* pills */
  /* .pill-container {
    width: 300%;
  } */
}

/* Phone size */
@media screen and (max-width: 500px) {
  /* pills */
  /* .pill-container {
    width: 450%;
  } */
}

/************ Resume Css ************/
.skill {
  padding: 0 0 0px 0;
  position: relative;
  /* padding-top: 20px; */
  background-color: #276174;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.skill-bx {
  background: #70a9a1;
  border-radius: 64px;
  text-align: center;
  padding: 30px 50px;
}
.skill h2 {
  margin-top: 10%;
  font-size: 40px;
  font-weight: 700;
  margin-left: 0;
  margin-right: 0;
}
.skill p {
  color: #22404b;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}
.button-test {
  border-radius: 30px;
  background-color: #40798c;
  color: #fffef8;
  padding: 15px;
  border: none;
  margin-top: 30px;
}
.skill img {
  width: 60%;
}
@media screen and (max-width: 600px) {
  div.skill-bx {
    text-align: center;
    width: 90%;
  }

  .skill div.align-items-center.row {
    display: flex;
    justify-content: center;
  }

  .skill h2 {
    margin-top: 10%;
    font-size: 40px;
  }
  .skill p {
    font-size: 14px;
    margin: 14px 0 75px 0;
  }
  .col-md-5 img {
    width: 50%;
  }
}

/************ Projects Css ************/
.project {
  padding-top: 80px;
  position: relative;
  background-color: #276174;
}
.project h2 {
  font-size: 70px;
  font-weight: 700;

  color: #f6f1d1;
  margin-bottom: 55px;
}
.project p {
  color: #fffef3;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.project h4 {
  font-size: 30px;
}

.project a {
  color: #fffff3;
  font-size: 30px;
  text-decoration: none;
}

/* Tags */
.tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 10px;
}

.tags {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: #70a9a1;
  border-radius: 20%/80%;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  /* width: 130px; */
  display: flex;
  justify-content: center;
  align-content: center;
}

.project .tags p {
  font-size: 12px;
  margin-top: 10px;
  width: 100%;
  text-transform: uppercase;
  white-space: nowrap;
}

.project-box {
  margin-bottom: 50px;
}

.proj-imgbx {
  position: relative;
  height: 300px;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  color: #f6f1d1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  border: #70a9a1 5px solid;
  margin-bottom: 15px;
}

.proj-imgbx::before {
  content: "";
  background: #70a9a1;
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
@media screen and (max-width: 990px) {
  .project {
    padding: 80px 0;
    position: relative;
    background-color: #276174;
  }
  .project h2 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #f6f1d1;
  }
  .project p {
    color: #fffef3;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
}

/************ Contact Css ************/
.contact {
  background: #40798c;
  padding: 0 0 50px 0;
}

.contact img {
  width: 100%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 20px;
}
.contact p {
  margin-bottom: 20px;
}

img.transition {
  width: 100%;
}

@media screen and (max-width: 760px) {
  .contact img {
    width: 50%;
  }
}

/************ Footer Css ************/
.footer {
  padding: 10 0 50px 0;
  margin-top: 50px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/************ About Page CSS ************/
section.about {
  margin-top: 100px;
  /* padding: 0 180px 0 180px; */
  background-color: #276174;
}

.about .header-container {
  padding-top: 100px;
  padding-bottom: 25px;
}

.about h2 {
  font-size: 70px;
  font-weight: 700;
  color: #f6f1d1;
  margin-bottom: 30px;
}

.about img {
  /* animation: updown 3s linear infinite; */
  width: 300px;
  border-radius: 2000px;
  border: 5px solid #70a9a1;
  margin-bottom: 20px;
}

.center-profile {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Extra Small*/
@media screen and (max-width: 576px) {
  .about img {
    width: 200px;
  }
  .center-profile {
    align-items: center;
  }
}
/* Small*/
@media screen and (max-width: 768px) {
  .center-profile {
    align-items: start;
  }
}
/* Medium */
@media screen and (max-width: 992px) {
  .about img {
    width: 200px;
  }
  .center-profile {
    align-items: center;
  }
}
/* Large */
@media screen and (max-width: 1200px) {
  .about img {
    width: 250px;
  }
}

/************ Project Page Header CSS ************/
.project-header {
  margin-top: 150px;
  width: 100%;
}

.header-text-container {
  width: 100%;
  font-size: 20px;
  margin-bottom: 80px;
}

.project-text-container h2 {
  font-size: 70px;
  font-weight: 700;
  color: #f6f1d1;
}

.project-header-img {
  width: 100%;
  padding: 0;
}

.project-pill-container {
  max-width: 100vw;
  padding: 0;
  height: 230px;
  margin-top: -80px;
}

/* title */
.project-page h2 {
  font-size: 50px;
  font-weight: 700;
  color: #f6f1d1;
  margin-bottom: 30px;
}

/* box below floating pills */
.lighter-box {
  background-color: #40798c;
}

/* x-Large */
@media screen and (max-width: 1280px) {
  .project-header-img {
    width: 90%;
  }
  .header-text-container {
    margin-bottom: 0px;
  }
}
/* Large */
@media screen and (max-width: 1200px) {
  .project-header-img {
    width: 80%;
  }
  .header-text-container {
    margin-bottom: 0px;
    width: 40%;
  }
}
/* Medium */
@media screen and (max-width: 992px) {
  .project-header-img {
    width: 100%;
  }
  .header-text-container {
    margin-bottom: 10px;
    width: 100%;
  }
}
/* Small*/
@media screen and (max-width: 768px) {
  .project-header-img {
    width: 100%;
    margin-bottom: 10px;
  }
}
/* Extra Small*/
@media screen and (max-width: 576px) {
}

/************ Project Page Overview CSS ************/
.project-overview-container {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  padding-top: 100px;
  padding-bottom: 80px;
  width: 100%;
  background-color: #40798c;
}

.overview-textbox {
  background-color: #70a9a1;
  border-radius: 50px;
  padding: 30px 40px;
  width: 100%;
}

.overview-textbox h3 {
  color: #f6f1d1;
}

.overview-textbox li {
  line-height: 20px;
}

img.lightbulb {
  width: 100%;
}

.roles-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}

.roles {
  margin-top: 10px;
  margin-right: 10px;
  background-color: #40798c;
  border-radius: 20%/80%;
  height: 35px;
  /* width: 130px; */
  padding: 10px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
}

/* Extra Small*/
@media screen and (max-width: 576px) {
  img.lightbulb {
    width: 50%;
  }
}
/* Small*/
@media screen and (max-width: 768px) {
  img.lightbulb {
    width: 50%;
  }
}
/* Medium */
@media screen and (max-width: 992px) {
  img.lightbulb {
    width: 50%;
  }
}
/* Large */
@media screen and (max-width: 1200px) {
  img.lightbulb {
    width: 50%;
  }
}

/************ G20/IF20 Project page CSS ************/
.case-study-container {
  width: 60%;
  margin: auto;
  margin-top: 80px;
}

h3.case-study {
  margin-bottom: 30px;
}

/************ Crafter's Community Yarn Project page CSS ************/

h3.figma-title {
  margin-bottom: 30px;
  margin-top: 40px;
}
